@tailwind base;
@tailwind components;
@tailwind utilities;

html { height: 100%; }
body { height: 100%; }


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}

button > img {
  margin-right: 5px;
}

@font-face {
  font-family: PTSans;
  src: url(./fonts/PTSans-Regular.ttf);
}

@font-face {
  font-family: PTSansBold;
  src: url(./fonts/PTSans-Bold.ttf);
}

@font-face {
  font-family: Urbanist;
  src: url(./fonts/Urbanist-VariableFont_wght.ttf);
}

.owl-item {
  width: 200px !important;
}

.owl-next {
  background: #FFCC57 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -40px;
  background-image: url('../public/images/Icons/leftArrow.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  top: 40%;
}

.owl-next span {
  display: none;
}

.owl-prev span {
  display: none;
}

.owl-prev {
  background: #FFCC57 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -40px;
  background-image: url('../public/images/Icons/rightArrow.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  top: 40%;
}

.destination-owl .owl-item {
  width: 400px !important;
  margin: auto;
}

.destination-owl .owl-next {
  background: white !important;
  box-shadow: 0px 4px 17px rgba(49, 49, 49, 0.25);
}

.destination-owl .owl-next span {
  display: block;
  color: black
}

.destination-owl .owl-prev span {
  display: block;
  color: black
}

.destination-owl .owl-prev {
  background: white !important;
  box-shadow: 0px 4px 17px rgba(49, 49, 49, 0.25);
}

.testimonial-owl .owl-item {
  width: 500px !important;
}

.testimonial-owl .owl-next {
  background: white !important;
  box-shadow: 0px 4px 17px rgba(49, 49, 49, 0.25);
  right: -25px !important;
}

.testimonial-owl .owl-prev {
  background: white !important;
  box-shadow: 0px 4px 17px rgba(49, 49, 49, 0.25);
  left: -25px !important;
}

.testimonial-owl .owl-next span {
  display: block;
  color: black
}

.testimonial-owl .owl-prev span {
  display: block;
  color: black
}


.owl-stage {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

p {
  color: #002755;
}